<template>
    <div>
        <el-button type="text" @click="dialogFormVisible = true">Edit</el-button>

        <el-dialog :title="'Edit alerts: ' + area.name" :visible.sync="dialogFormVisible">

            <p>Enter your own thresholds or use the recommended ones.</p>

            <div class="form-row mb-2">
                <div class="col-4"><strong>Metric</strong></div>
                <div class="col-2"><strong>Min</strong></div>
                <div class="col-2"><strong>Max</strong></div>
            </div>
            <ValidationObserver v-slot="{ invalid }">
                <div class="form-row form-group" v-for="(metric, key) in metrics" :key="metrics[key].short_name">
                    <div class="col-4"><label>{{ metric.short_name }}</label></div>
                    <div class="col-2">
                        <ValidationProvider rules="numeric" v-slot="{ passed, failed }">
                            <fg-input type="text" :error="failed ? 'Should be numeric' : null" :hasSuccess="passed">
                                <input type="text" class="form-control" v-model="form.metrics[key].minimum">
                            </fg-input>
                        </ValidationProvider>
                    </div>
                    <div class="col-2">
                        <ValidationProvider rules="numeric" v-slot="{ passed, failed }">
                            <fg-input type="text" :error="failed ? 'Should be numeric' : null" :hasSuccess="passed">
                                <input type="text" class="form-control" v-tooltip="metrics[key].warning" v-model="form.metrics[key].maximum">
                            </fg-input>
                        </ValidationProvider>
                    </div>
                    <div class="col"><span class="form-text">{{ metric.unit_symbol }}</span></div>
                </div>

                <el-button class="mb-3" type="secondary" @click="setDefaults">Set recommended thresholds for all</el-button>

                <div class="form-row mb-2">
                    <div class="col-4"><strong>Enable alerts</strong></div>
                    <div class="col-6">
                        <l-switch v-model="form.is_active"></l-switch>
                    </div>
                </div>
                <div class="form-row mb-2">
                    <div class="col-4"><strong>Recipient</strong></div>
                    <div class="col-6">
                        <ValidationProvider ref="observer" rules="required" v-slot="{ passed, failed }">
                            <fg-input  type="email" :error="failed ? 'must provide valid email': null" :hasSuccess="passed">
                                <input type="email" value="" class="form-control" v-model="form.email" multiple>
                            </fg-input>
                        </ValidationProvider>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="submit">Confirm</el-button>
                </span>
            </ValidationObserver>
        </el-dialog>
    </div>

</template>
<script>
    import api from '@/api';
    import { Button, Dialog, Form, FormItem, Col } from 'element-ui'
    import { Switch as LSwitch } from 'src/components/index'

    import { extend } from "vee-validate";
    import { numeric, email, required } from "vee-validate/dist/rules";
    import { apiHandler } from '@/mixins/apiHandler';

    extend("numeric", numeric);
    extend("email", email);
    extend("required", required);

    export default {
        components: {
            [Button.name]: Button,
            [Dialog.name]: Dialog,
            [Form.name]: Form,
            [Col.name]: Col,
            [FormItem.name]: FormItem,
            LSwitch,
        },
        mixins: [apiHandler],
        props: {
            'area': Object
        },
        data() {
            return {
                'dialogFormVisible': false,
                'form': {
                    'metrics': {},
                    'is_active': true,
                    'email': '',
                },
            }
        },
        mounted: function () {
            this.form.metrics = Object.keys(this.$store.getters.metrics).reduce((carry, key) => {
                const alert = this.area.alerts.find(alert => alert.metric === key);

                carry[key] = {
                    'minimum': alert ? this.convert(key, alert.minimum) : null,
                    'maximum': alert ? this.convert(key, alert.maximum) : null,
                };
                return carry;
            }, {});

            if (this.area.alerts.length > 0) {
                this.form.is_active = this.area.alerts[0].is_active;
                this.form.email = this.area.alerts[0].email;
            }
        },
        methods: {
            setDefaults: function () {
                for (let key in this.form.metrics) {
                    this.form.metrics[key].minimum = this.convert(key, this.$store.getters.metrics[key].thresholds.minimum);
                    this.form.metrics[key].maximum = this.convert(key, this.$store.getters.metrics[key].thresholds.maximum);
                }
            },
            submit: async function () {
                const isValid = await this.$refs.observer.validate();
                if(!isValid.valid){
                  return;
                }
                const alerts = Object.keys(this.form.metrics).reduce((carry, metric_key) => {
                    const minimum = parseFloat(this.form.metrics[metric_key].minimum);
                    const maximum = parseFloat(this.form.metrics[metric_key].maximum);

                    if (!(isNaN(minimum) && isNaN(maximum))) {
                        const temporary_object = {'metric': metric_key,}
                        if (!isNaN(minimum)) {
                            temporary_object['minimum'] = Math.round(this.convertBack(metric_key, this.form.metrics[metric_key].minimum));
                            }
                        if (!isNaN(maximum)) {
                            temporary_object['maximum'] = Math.round(this.convertBack(metric_key, this.form.metrics[metric_key].maximum));
                        }
                        carry.push(temporary_object)
                    }

                    return carry;
                }, []);

                //.filter((alert) => alert.minimum.toString().length + alert.maximum.toString().length > 0);
                const result = await api.saveAreaAlerts(this.area.area_hashid, alerts, this.form.is_active, this.form.email);

                if('response' in result){
                  if('errors' in result.response.data){
                     this.responseHandler('error',result.response);
                     return;
                  }
                }

                this.area.alerts = result.data.data;
                this.$toasted.success("Alert Updated");
                this.dialogFormVisible = false;

            },
            'convert': function (metric_key, value) {
                const metric = this.$store.getters.metrics[metric_key];

                if ('convert' in metric) {
                    value = metric.convert(value);

                    value = Math.round(value);
                }

                return value;
            },
            'convertBack': function (from_key, value) {
                const metric = this.$store.getters.metrics[from_key];

                if ('convertBack' in metric) {
                    value = metric.convertBack(value)
                }

                return value;
            }
        },
        computed: {
            metrics () {
                const reject = ['ci'];

                return Object.keys(this.$store.getters.metrics)
                        .filter(value => !reject.includes(value))
                        .reduce((carry, key) => {
                            carry[key] = this.$store.getters.metrics[key];
                            return carry;
                        }, {});
            }
        }
    };
</script>
