<template>

  <button v-if="this.metric == 'ci'"
          type="button"
          class="btn rounded-circle"
          :class="[metricData.name]"
          disabled><strong>{{ typeof(value) === 'number' ? value : '-' }}</strong></button>
  <button v-else class="btn rounded-circle" :class="[style, metricData.name]" v-on:click="$emit('click', area, metric)">{{ typeof(value) === 'number' ? value : '-' }}</button>

</template>
<script>

  export default {
    props: ['area', 'metric', 'metricData'],
    data: function() {
      return {
        //assign to local variables as we may modify
        min: this.metricData.thresholds.minimum,
        max: this.metricData.thresholds.maximum,
        original_value: 0
      }
    },
    computed: {
      value: function() {
        let value = 0
        if (this.area.readings && this.area.readings[this.metric] !== null) {
          value = this.area.readings[this.metric]
          this.original_value = value

          const metric = this.$store.getters.metrics[this.metric]

          if ('convert' in metric) {
            value = metric.convert(value)
          }

          value = +value.toFixed(1)
        } else {
          value = null
        }

        return value
      },
      style: function() {
        if (typeof this.value !== 'number') {
          // The value is not a number so can't be used for comparison
          return 'btn-light'
        }

        // Are alerts set, let's use those
        if (this.area.alerts) {
          const alert = this.area.alerts.find(alert => alert.metric === this.metric)
          if (alert && (this.original_value < alert.minimum || this.original_value > alert.maximum)) {
            // Alert met
            return 'btn-warning'
          }
        }

        // No alerts, then check the threshold settings
        if (
          (this.metricData.thresholds.minimum !== null && this.original_value < this.metricData.thresholds.minimum) ||
          (this.metricData.thresholds.maximum !== null && this.original_value > this.metricData.thresholds.maximum)
        ) {
          // Default threshold met
          return 'btn-warning'
        }

        // No issues found, then green
        return 'btn-success'
      }
    }
  }

</script>
<style scoped>

  button {
    width: 3rem;
    height: 3rem;
    padding: 0;
  }

  .Productivity {
    border: solid 1px #f8f9fa;
    background-color: #f8f9fa;
    opacity: 1;
    color: #87CB17;
  }

</style>
