<template>
	<div id="stats" class="alert-warning p-2 m-2 stats">
    <div class="d-flex flex-row justify-content-around">
			<strong>Avg: {{ average }}s </strong> <!--to convert Nan to zero -->
			<strong>Total: {{ total }}s </strong>
			<strong>Count: {{ count }} </strong>
			<button type="button" class="close" @click="$emit('close')" data-dismiss="alert" aria-label="Close">
    		<span aria-hidden="true">&times;</span>
  		</button>
    </div>
		<div class="d-flex justify-content-center mt-2" v-if="count">
			<button class="btn btn-sm btn-outline " @click="reset()">Reset</button>
			<button class="btn btn-sm btn-outline ml-2"  @click="displayAll = !displayAll">Show All..</button>
		</div>
		<span v-show="displayAll" v-for="stat in statsFilter()" v-if="stat"> {{ stat }}, </span>
	</div>

</template>
<script>
  import router from '../../../router'
  import store from '../../../store'
	import api from '@/api';

  export default {
    data: function () {
      return {
        stats: [],
				average: 0,
				total: 0,
				count: 0,
				displayAll: false
      }
    },
    methods: {
			getStats(){
				this.stats =  api.metrics();
			},
			async reset(){
					await api.resetMetrics();
					this.getStats();
			},
			statsFilter(){
						let sum = 0;
						let arr = this.stats.map(stat => {
							if ((typeof stat == 'object')){
								sum += parseFloat(stat.perf_metric);
								return parseFloat(stat.perf_metric);
							}
						});
						this.average = sum > 0 ? (sum / arr.length).toFixed(3) : 0;
						this.total = sum.toFixed(3);
						this.count = arr.length;
						return arr;
			}
    },
		created(){
			this.getStats();
		},
  }
</script>
<style scoped>
	#stats {
		position: fixed;
		bottom: 10%;
		opacity: 0.8;
		left: 0;
		width: 100%;
		z-index: 999;
	}
</style>
