<template>
    <div class="row">
        <div class="col-12 d-flex justify-content-end flex-wrap">
            <el-input type="search" class="mb-3 w-25" placeholder="Search..." v-model="query" aria-controls="datatables" />
        </div>
        <div class="col-sm-12">
            <Loader v-if="loading" />
            <table class="table text-center table-overview" style="table-layout: fixed;" v-else>
                <thead>
                    <tr>
                        <th>Area</th>
                        <th v-for="metric in metrics">
                            <strong v-if="metric.short_name">{{ metric.short_name }}</strong>
                            <strong v-else>{{ metric.name }}</strong>
                            <br>{{ metric.unit_symbol }}
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="area in areas">
                        <th>{{ area.name }}</th>
                        <td v-for="(metric, key) in metrics" v-text="getRange(area.alerts, key)"></td>
                        <td><EditModal :area="area"></EditModal></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <l-pagination class="pagination-no-border" v-model="pagination.current_page" :per-page="pagination.per_page" :total="pagination.total" v-if="!loading"></l-pagination>
        </div>
    </div>
</template>
<script>
    import api from '@/api';
    import { Select } from 'element-ui'
    import {Pagination as LPagination} from 'src/components/index'
    import EditModal from './EditModal'
    import { debounce } from 'vue-debounce'
    import Loader from '@/components/Loader'

    export default {
        components: {
            LPagination,
            EditModal,
            Loader
        },
        methods: {
            async fetch () {
                this.loading = true;
                const result = await api.getOrganizationAreas(this.pagination.current_page, this.query);

                this.loading = false;
                this.areas = result.data;

                this.pagination = {
                    per_page: result.meta.per_page,
                    current_page: result.meta.current_page,
                    total: result.meta.total,
                };
            },
            getRange (alerts, metric) {
                const alert = alerts.find(alert => alert.metric === metric);

                if (!alert) {
                    return '-';
                }
                if ((alert.minimum == null) && !isNaN(alert.maximum)) {
                    return ' < ' + this.convert(metric, alert.maximum);
                }
                if (!isNaN(alert.minimum) && (alert.maximum == null)) {
                    return ' > ' + this.convert(metric, alert.minimum);
                }
                return this.convert(metric, alert.minimum) + ' - ' + this.convert(metric, alert.maximum);
            },
            'convert': function (metric_key, value) {
                const metric = this.$store.getters.metrics[metric_key];

                if ('convert' in metric) {
                    value = metric.convert(value);

                    value = Math.round(value);
                }

                return value;
            }
        },
        watch:{
            'pagination.current_page': function (newVal, oldVal) {
                if (newVal !== this.oldVal) {
                    this.fetch()
                }
            },
            'query': debounce(function (newVal, oldVal) {
                if (newVal !== this.oldVal) {
                    this.pagination.current_page = 1;
                    this.fetch();
                }
            }, 300)
        },
        mounted () {
            this.fetch(this.current_page);
        },
        computed: {
            metrics () {
                const reject = ['ci'];

                return Object.keys(this.$store.getters.metrics)
                        .filter(value => !reject.includes(value))
                        .reduce((carry, key) => {
                            carry[key] = this.$store.getters.metrics[key];
                            return carry;
                        }, {});
            }
        },
        data () {
            return {
                loading: false,
                areas: [],
                pagination: {
                    per_page: null,
                    current_page: 1,
                    total: 0
                },
                query: ''
            }
        }
    }
</script>
<style>
</style>
