export default {
	data() {
		return {
  			hexColors: [
          "#001f3f",
          "#0074D9",
          "#7FDBFF",
          "#39CCCC",
          "#3D9970",
          "#2ECC40",
          "#FFDC00",
          "#FF851B",
          "#FF4136",
          "#85144b",
          "#B10DC9",
          "#111111",
          "#AAAAAA",
          "#DDDDDD"
        ],
        current: 0
  		}
  	},
	methods: {
		getColor(index = null){
      //recursive to reset back to zero
      if(index > this.hexColors.length - 1){
        return this.getColor(index - this.hexColors.length)
      }
      return this.hexColors[index];
    }
  },
}
