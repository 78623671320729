<template>
	<div class="row">
			<div class="col-12 d-flex justify-content-between flex-wrap">
				<card class="w-100">
					<h4 class="card-title">Manage a different Organisation</h4>
					<p class="text-muted">Select organization from the list below:</p>
					<div 	v-for="org in $store.getters.user.organizations" class="form-group">
					<Radio
						v-model="current_org"
						:label="org.id"
						>
						{{ org.name }}
					</Radio>
					</div>

					<button @click="save" class="btn-fill btn btn-info">
						Change Organisation
					</button>
				</card>

			</div>

		</div>

  </div>
</template>
<script>
	import { mapGetters } from 'vuex'
  import Radio from 'src/components/Inputs/Radio.vue'

  export default {
    components: {Radio },
    data() {
      return {
        current_org: this.$store.getters.active_organization.id,
      };
    },
		methods: {
			save(){
					this.$store.commit('setActiveOrganization', this.current_org);
					this.$router.push('/admin/overview');
			}
		},
  }

</script>
