import { render, staticRenderFns } from "./ReadingButton.vue?vue&type=template&id=482e8dde&scoped=true&"
import script from "./ReadingButton.vue?vue&type=script&lang=js&"
export * from "./ReadingButton.vue?vue&type=script&lang=js&"
import style0 from "./ReadingButton.vue?vue&type=style&index=0&id=482e8dde&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482e8dde",
  null
  
)

export default component.exports