<template>
    <div>
        <el-button type="primary" @click="dialogFormVisible = true">Add new area</el-button>

        <el-dialog title="Create Area" :visible.sync="dialogFormVisible">
            <p>An area defines a space with a single significant volume of air or HVAC zone.</p>

            <form method="#" action="#" @submit.prevent>
                <fg-input label="Area name (Room 201, Boardroom, Ward 4, etc.)" type="text" v-model="form.name"></fg-input>
                <fg-input label="Site (select from options)">
                    <el-select class="select-default" placeholder="Select..." v-model="form.place_id">
                        <el-option v-for="place in places" :key="place.id" class="select-default" :label="place.name" :value="place.id"></el-option>
                    </el-select>
                </fg-input>
                <fg-input label="Floor (optional)" type="text" v-model="form.floor"></fg-input>
                <fg-input label="Device MAC address">
                    <el-select class="select-default" placeholder="Select..." v-model="form.device_id">
                        <el-option v-for="unpaired_device in unpaired_devices" :key="unpaired_device.id" class="select-default" :label="unpaired_device.mac_address" :value="unpaired_device.id"></el-option>
                    </el-select>
                </fg-input>
            </form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button type="primary" @click="submit">Confirm</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import api from '@/api';
    import { Button, Dialog, Form, FormItem, Col, Select, Option } from 'element-ui'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            [Button.name]: Button,
            [Dialog.name]: Dialog,
            [Form.name]: Form,
            [Col.name]: Col,
            [FormItem.name]: FormItem,
            [Select.name]: Select,
            [Option.name]: Option,
        },
        data() {
            return {
                'dialogFormVisible': false,
                'form': {
                    'name': '',
                    'place_id': '',
                    'floor': '',
                    'device_id': ''
                }
            };
        },
        computed: {
            ...mapGetters([
                'places',
                'unpaired_devices'
            ])
        },
        methods: {
            submit: async function () {
                const area = await api.storeArea(this.form);

                this.$emit('areaCreated', area);

                this.dialogFormVisible = false;
            }
        }
    };
</script>
