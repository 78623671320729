<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-lg-8">
        <div class="stacked-form card">
          <div class="card-body">
            <div>
              <p>
                We're here to help. Email us at <a href="mailto:support@airsset.com?subject=App support enquiry">support@airsset.com</a> with any general question or feedback.
              </p>
            </div>
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import store from "@/store";

import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);

export default {
  /*data() {
    return {
      from: store.getters.user.email,
      subject: "",
      message: "",
      emailSubmitted: false,
      responseText:
        "Something is not right, we could not send your message. Please go back to try again."
    };
  },
  methods: {
    async sendEmail(e) {
      e.preventDefault();

      const res = await api.sendSupportEmail(
        this.from,
        this.subject,
        this.message
      );
      if (res.status == 204) {
        this.emailSubmitted = true;
        this.subject = "";
        this.message = "";
        this.from = store.getters.user.email;
        this.responseText =
          "Thank you, we got your message. Our representative will contact you shortly.";
      } else {
        this.emailSubmitted = true;
      }
    }
  }*/
};
</script>
<style></style>
