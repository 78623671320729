<template>
    <div>
        <div class="mb-3">
          <div class="d-flex flex-row">
            <multiselect
              :options="areas"
              label="name"
              track-by="name"
              :multiple="true"
              class="pb-2 area-select"
              @remove="removeArea"
              v-model="selected_areas"
              :close-on-select="false"
              @close="refreshChart()"
              >
            </multiselect>
            <button class="btn btn-sm btn-link ml-2" @click="refreshChart()"><i class="fa fa-refresh"></i></button>
          </div>

            <el-select v-model="active_metric" class="select-secondary" placeholder="Metric" @change="refreshChart()">
                <el-option
                    :value="key" :label="metric.name"
                    v-for="(metric, key) in this.metrics"
                    v-if="key!='ci'"
                    :key="key">
                  </el-option>
            </el-select>

        </div>

        <card v-if="!selected_areas.length">
            <p class="text-muted mb-0">Select the area(s) and metric above.</p>
        </card>

        <card v-if="selected_areas.length">
            <AirssetChartMulti
              @hook:mounted="refreshChart()"
              :area="active_area"
              :metric="active_metric"
              :key="active_area.id"
              :chart_areas="chart_areas"
              ref="chart">
            </AirssetChartMulti>
        </card>
    </div>
</template>
<script>
    import api from '@/api';
    import { Select, Option } from 'element-ui'
    import AirssetChartMulti from '@/components/AirssetChartMulti';
    import Multiselect from 'vue-multiselect'
    import { mapGetters } from 'vuex'
    import Loader from '@/components/Loader'

    export default {
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
            AirssetChartMulti,
            Multiselect
        },
        data() {
            const minus_24h = new Date();
            minus_24h.setHours(minus_24h.getHours() - 24);

            return {
                'areas': [],
                'active_area': null,
                'active_area_id': null,
                'active_metric': null,
                'selected_areas': [],
                'chart_areas': null,
            };
        },
        'computed': {
            'date_string': function () {
                if (this.range == '24h') {
                    return 'Today';
                } else if (this.range === 'week') {
                    return 'w/c ' + this.from.toLocaleString('en-US', {
                        dateStyle: 'medium',
                    });
                } else if (this.range === 'month') {
                    return this.from.toLocaleString('en-US', {
                        'month': 'long',
                        'year': 'numeric'
                    });
                }

                return this.from.toLocaleString('en-US', {
                    dateStyle: 'medium',
                });
            },
            ...mapGetters([
                'metrics'
            ])
        },
        'methods': {
            'getAreas': async function(){
              this.areas = (await api.getOrganizationAreas()).data;
            },
            'removeArea': function (area){
              this.$refs.chart.fetchData(area,true);
            },
            'refreshChart': async function (){
              await this.getAreas();
              let index = 0;
              this.selected_areas.forEach(function (element) {
                  element.legendIndex = index++;
                });
              this.chart_areas = JSON.parse(JSON.stringify(this.selected_areas));

            },
            'previousDate': function () {
                this.from = new Date(this.from);
                this.to = new Date(this.to);

                if (this.range === 'day') {
                    this.from.setDate(this.from.getDate() - 1);
                    this.to.setDate(this.to.getDate() - 1);
                } else if (this.range === 'week') {
                    this.from.setDate(this.from.getDate() - 7);
                    this.to.setDate(this.to.getDate() - 7);
                } else if (this.range === 'month') {
                    this.from.setMonth(this.from.getMonth() - 1);
                    this.to.setMonth(this.to.getMonth() - 1);
                }
            },
            'nextDate': function () {
                this.from = new Date(this.from);
                this.to = new Date(this.to);

                if (this.range === 'day') {
                    this.from.setDate(this.from.getDate() + 1);
                    this.to.setDate(this.to.getDate() + 1);
                } else if (this.range === 'week') {
                    this.from.setDate(this.from.getDate() + 7);
                    this.to.setDate(this.to.getDate() + 7);
                } else if (this.range === 'month') {
                    this.from.setMonth(this.from.getMonth() + 1);
                    this.to.setMonth(this.to.getMonth() + 1);
                }
            }
        },
        'watch': {
            'range': function (range) {
                this.from = new Date(this.from);

                if (range == 'day') {
                    this.from.setHours(0, 0, 0, 0);
                    this.to = new Date(this.from);
                    this.to.setDate(this.to.getDate() + 1);
                } else if (range == 'week') {
                    const day_of_week = this.from.getDay();
                    const days_since_monday = day_of_week == 0 ? 6 : day_of_week - 1;

                    this.from.setHours(0, 0, 0, 0);
                    this.from.setDate(this.from.getDate() - (7 + days_since_monday));
                    this.to = new Date(this.from);
                    this.to.setDate(this.from.getDate() + 7);
                } else if (range == 'month') {
                    this.from.setHours(0, 0, 0, 0);
                    this.from.setDate(1);
                    this.to = new Date(this.from);
                    this.to.setMonth(this.to.getMonth() + 1);
                } else {
                    this.from = new Date();
                    this.from.setHours(this.from.getHours() - 24);
                    this.to = new Date();
                }
            },
            'active_area_id': function (active_area_id) {
                this.active_area = this.areas.find((item) => item.id === active_area_id);
            }
        },
        async mounted() {
            await this.getAreas();
            this.active_area_id = this.areas[0].id;
            //set metric to first one available
            this.active_metric = ('temp' in this.metrics) ? 'temp' : Object.keys(this.metrics)[0];
            //this.selected_areas.push(this.areas[0]); //add a default starting area
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
  .area-select{
    width: 80%;
  }
</style>
