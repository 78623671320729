import Axios from 'axios'
import router from './router'
import store from './store'

const axios = Axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

const DEBUG = process.env.VUE_APP_ENV !== "production";
var perf_metrics = new Array();

axios.interceptors.request.use(request => {
    const api_token = localStorage.getItem('api_token');

    if (api_token) {
        request.headers.Authorization = 'Bearer ' + api_token;
    }

    return request;
});

axios.interceptors.response.use((response,metrics) => {

			if(typeof response.data.metadata !== null){
				perf_metrics.push(response.data.metadata);
			}
			return response;
		},
		error => {
    if (error.response.status === 401) {
        localStorage.removeItem('api_token');
        store.commit('unsetUser');
        if(router.currentRoute.path != '/login'){
          router.push('/login');
        }

    }
    return error;
});

export default {
    'getApiToken': async function (email, password) {
          const response = await axios.post('auth/token', {
            'email': email,
            'password': password,
            'device_name': 'webapp'
          });
        return response;
    },
    'getUser': async function () {
        const response = await axios.get('auth/user');

        return response.data.data;
    },
    'getMetrics': async function () {
        const response = await axios.get('organizations/' + store.getters.active_organization.id + '/metrics');
        return response.data;
    },
    'getOrganizationDevices': async function (page = 1, query = '') {
        const organization_id = store.getters.active_organization.id;

        const response = await axios.get('organizations/' + organization_id + '/devices?page=' + page + '&query=' + query);

        return response.data;
    },
    'saveAreaAlerts': async function (area_id, alerts, is_active, email) {
        const response = await axios.post('areas/' + area_id + '/alerts', {
            'alerts': alerts,
            'is_active': is_active,
            'email': email
        });

        return response;
    },
    'getOrganizationAreas': async function (page = 1, query = '') {
        const organization_id = store.getters.active_organization.id;

        const response = await axios.get('organizations/' + organization_id + '/areas?page=' + page + '&query=' + query);

        return response.data;
    },
    'getOrganizationPlaces': async function () {
        const organization_id = store.getters.active_organization.id;

        const response = await axios.get('organizations/' + organization_id + '/places');

        return response.data.data;
    },
    'getUnpairedDevices': async function () {
        const response = await axios.get('devices');

        return response.data.data;
    },
    'storeArea': async function (values) {
        const response = await axios.post('areas', {
            'name': values.name,
            'place_id': values.place_id,
            'floor': values.floor,
            'device_id': values.device_id
        });

        return response.data.data;
    },
    'updateArea': async function (area_id, values) {
        const response = await axios.put('areas/' + area_id, {
            'name': values.name,
            'place_id': values.place_id,
            'floor': values.floor
        });

        return response.data.data;
    },
    'getAreaDevices': async function (area_id) {
        const response = await axios.get('areas/' + area_id + '/devices');

        return response.data.data;
    },
    'getDeviceHistory': async function (device_id, query) {
        const response = await axios({
            'method': 'get',
            'url': (store.getters.active_organization.is_demo ? process.env.VUE_APP_DEMO_DATA_URL : process.env.VUE_APP_DATA_URL + device_id) + '/data/history',
            'params': query,
            'validateStatus': function(status) {
                return [200, 400].includes(status);
            },
        });

        return response.data;
    },
    'getAreaHistory': async function (area_hashid, query) {
        const response = await axios({
            'method': 'get',
            'url': (store.getters.active_organization.is_demo ? process.env.VUE_APP_DEMO_DATA_URL : process.env.VUE_APP_DATA_URL + area_hashid) + '/data/history?t='+Date.now(),
            'params': query,
            'validateStatus': function(status) {
                return [200, 400].includes(status);
            },
        });

        return response.data;
    },
    'getDeviceLatest':  async function (device_id) {
        const response = await axios({
            method: 'get',
            url: store.getters.active_organization.is_demo ? process.env.VUE_APP_DEMO_DATA_URL + '/data' : process.env.VUE_APP_DATA_URL + device_id + '/data',
            validateStatus: function(status) {
                return [200, 400].includes(status);
            },
        });

        return response.data;
    },
    'getAreaLatest': async function (area_hashid) {
        const response = await axios({
            method: 'get',
            url: store.getters.active_organization.is_demo ? process.env.VUE_APP_DEMO_DATA_URL + '/data' : process.env.VUE_APP_DATA_URL + area_hashid + '/data',
            validateStatus: function(status) {
                return [200, 400].includes(status);
            },
        });

        return response.data;
    },
    'getOrganizationReports': async function () {
        const organization_id = store.getters.active_organization.id;

        const response = await axios.get('organizations/' + organization_id + '/reports');

        return response.data.data;
    },
    'sendSupportEmail': async function (from, subject, body) {
        const response = await axios.post('support/email',{
            'from': from,
            'subject': subject,
            'body': body
        })
        return response
    },
    'sendSupportRequest': async data => await axios.post('support-requests', { data }),
	'metrics' : function () {
		return perf_metrics;
	},
/*    'sendExpertRequest': async data => await axios.post('support-sendmail', { data }),
	'metrics' : function () {
		return perf_metrics;
	}, */
    'sendExpertRequest': function (message, areas, metric, from_date, to_date) {
        return axios.post('support-sendmail',{
            'message': message,
            'areas': areas,
            'metric': metric,
            'from_date': from_date,
            'to_date': to_date
        });
    },
	'resetMetrics' : function () {
		perf_metrics = new Array();
	}
};
