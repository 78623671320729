<template>
<div class="text-center py-2 alert alert-secondary">
  <div class="spinner-border " role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
</template>

<script>

  export default {
    name: "loader",
  }

</script>
