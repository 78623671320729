<template>
    <div>
        <alerts-table></alerts-table>
    </div>
</template>
<script>
    import AlertsTable from './Table';

    export default {
        components: {
          AlertsTable
        }
    }
</script>
<style>
</style>
