/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.3.1
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'
import VueDebounce from 'vue-debounce'
import LightBootstrap from './light-bootstrap-main'
import Toasted from 'vue-toasted';
import PortalVue from 'portal-vue'

import App from './App.vue'
import router from './router'
import store from './store'

import firebase from "firebase/app";
import "firebase/auth";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   Vue,
//   dsn: "https://48a5dd0cfb3a4a8587869c93e842334b@o911452.ingest.sentry.io/5847655",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

import * as FullStory from '@fullstory/browser';

Vue.use(LightBootstrap)
Vue.use(VueDebounce)
Vue.use(Toasted, {
  position: 'bottom-center',
  duration: 1700,
})
Vue.use(PortalVue)

FullStory.init({ orgId: '13J79M' });
Vue.prototype.$FullStory = FullStory;

/* eslint-disable no-new */
new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
})

const firebase_config = {
    apiKey: "AIzaSyBQhZ8OKv5DQpSdOxDMdbLkSvFnclXXVps",
    authDomain: "airsset-app-dev.firebaseapp.com",
    databaseURL: "https://airsset-app-dev.firebaseio.com",
    projectId: "airsset-app-dev",
    storageBucket: "airsset-app-dev.appspot.com",
    messagingSenderId: "36028991715",
    appId: "1:36028991715:web:7075fdace5e82cbda29dd6",
    measurementId: "G-97WKWD3ZN"
};

firebase.initializeApp(firebase_config);
