<template>
  <footer class="footer">
		<Metrics v-if="metricsVisible" @close="metricsVisible = false"/>

    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          <li>
            <a href="https://www.airsset.com">Home</a>
          </li>
          <li>
            <a href="#logout" @click.prevent="logout()">Log out</a>
          </li>
			    <li v-if="metricLink">
            <a href="#"  @click.prevent="showMetrics()">Metrics</a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; Airsset {{ year }}
      </div>
    </div>
  </footer>
</template>
<script>
  import router from '../../../router'
  import store from '../../../store'
	import Metrics from './Metrics'

  export default {
		components: {Metrics},
    data: function () {
      return {
        year: new Date().getFullYear(),
				metricsVisible: false,
				metricLink: process.env.VUE_APP_ENV !== "production"
      }
    },
    methods: {
      logout: function () {
        localStorage.removeItem('api_token');
        store.commit('unsetUser');

        router.push('/login');
      },
			showMetrics(){
				this.metricsVisible = !this.metricsVisible;
			}
    }
  }
</script>
<style>

</style>
