<template>
    <div>
        <areas-table></areas-table>
    </div>
</template>
<script>
    import AreasTable from './Table';

    export default {
        components: {
          AreasTable
        }
    }
</script>
