var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-layout',{attrs:{"pageClass":"login-page"}},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-8"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('fade-render-transition',[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"airsset-logo mb-4"},[_c('img',{attrs:{"src":"/images/logo.jpeg"}})]),_c('h3',{staticClass:"card-title text-center"},[_vm._v("Login")])]),_c('div',[(_vm.error)?_c('div',{staticClass:"alert alert-danger"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? 'The Email field is required': null,"hasSuccess":passed,"label":"Email address","name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The Password field is required': null,"hasSuccess":passed,"name":"password","label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[(!_vm.loading)?_c('div',[_c('button',{staticClass:"btn btn-fill btn-info btn-round btn-wd ",attrs:{"type":"submit"}},[_vm._v("Login")])]):_c('div',[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])]),_c('br')])])],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }