<template>
    <div class="row">
          <div class="col-12 d-flex justify-content-between flex-wrap">
              <el-input type="search" class="mb-3 w-25" placeholder="Search..." v-model="query" aria-controls="datatables" />
          </div>
          <div class="col-sm-12">
              <Loader v-if="loading" />
              <el-table v-else stripe style="width: 100%;" :data="areas" border>
                  <el-table-column prop="name" label="Area" align="left"></el-table-column>
                  <el-table-column prop="place.name" label="Site" align="left"></el-table-column>
                  <!--<el-table-column prop="floor" label="Floor" align="left"></el-table-column>-->
                  <el-table-column prop="mac_address" label="Device MAC Address"></el-table-column>
              </el-table>

          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <l-pagination class="pagination-no-border" v-model="pagination.current_page" :per-page="pagination.per_page" :total="pagination.total" v-if="!loading"></l-pagination>
          </div>
    </div>
</template>
<script>
    import api from '@/api';
    import { Table, TableColumn, Select } from 'element-ui'
    import {Pagination as LPagination} from 'src/components/index'
    import EditModal from './EditModal'
    import CreateModal from './CreateModal'
    import { debounce } from 'vue-debounce'
    import Loader from '@/components/Loader'

    export default {
        components: {
            LPagination,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            EditModal,
            CreateModal,
            Loader
        },
        data() {
            return {
                'loading': false,
                'areas': [],
                'pagination': {
                    'per_page': null,
                    'current_page': 1,
                    'total': 0
                },
                'query': ''
            };
        },
        methods: {
            async fetch () {
                this.loading = true;
                const result = await api.getOrganizationAreas(this.pagination.current_page, this.query);

                this.loading = false;
                this.areas = result.data;

                this.pagination = {
                    per_page: result.meta.per_page,
                    current_page: result.meta.current_page,
                    total: result.meta.total,
                };
            }

        },
        watch:{
            'pagination.current_page': function (newVal, oldVal) {
                if (newVal !== this.oldVal) {
                    this.fetch();
                }
            },
            'query': debounce(function (newVal, oldVal) {
                if (newVal !== this.oldVal) {
                    this.pagination.current_page = 1;
                    this.fetch();
                }
            }, 300)
        },
        async mounted () {
            this.fetch(this.current_page);

            const places = await api.getOrganizationPlaces();
            this.$store.commit('setPlaces', places);

            const unpaired_devices = await api.getUnpairedDevices();
            this.$store.commit('setUnpairedDevices', unpaired_devices);
        }
    }
</script>
<style>
</style>
