<template>
    <div>
        <div class="row">
            <div class="col-12 d-flex justify-content-end flex-wrap">
                <el-input type="search" class="mb-3 w-25" placeholder="Search..." v-model="query" aria-controls="datatables" />
            </div>
        </div>

        <div class="table-responsive" v-if="metrics">
				   <table class="table text-center table-overview" style="table-layout: fixed;">
                <thead>
                    <tr class="row-fixed" >
                        <th class="text-left fixed fixed-header"><strong v-on:dblclick="update()">Area</strong></th>
                        <th class="fixed" v-for="(metric,key) in metrics" v-if="!metric.hidden" :key="key" >
                              <strong v-if="metric.short_name" :title="metric.key"> {{metric.key }} {{ metric.short_name }}</strong>
                              <strong v-else>{{ metric.name }}</strong>
                              <br>{{ metric.unit_symbol }}
                        </th>
                        <th class="fixed" ></th>
                    </tr>
                    <tr class="spacer">
                        <td :colspan="Object.keys(this.metrics).length + 2"></td>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="area in results">
                        <tr v-if="area.last_reading" class="readings">
                            <td class="text-left font-weight-bold m-10" role="button" @click="expandChart(area)">{{ area.name }}<br><small class="text-muted">{{ area.place.name }}</small></td>
                            <template v-if="area.is_current">
                                <td v-for="(metric, key) in metrics" v-if="!metric.hidden" :class="{ 'selected bg-light': isSelected(area, key) }" :key="key">
                                  <ReadingButton :area="area" :metric="key" :metricData="metric" @click="showChart">
                                  </ReadingButton>
                                </td>
                            </template>
                            <template v-else>
                                <td :colspan="Object.values(metrics).filter((metric) => !metric.hidden).length" class="text-danger">
                                    Offline since {{ area.last_reading_to_locale }}
                                </td>
                            </template>
                            <td>
                                <button v-if="isSelected(area)" type="button" class="btn btn-link" @click="expandChart(area)"><i class="fa fa-angle-down"></i></button>
                                <button v-else type="button" class="btn btn-link" @click="expandChart(area)"><i class="fa fa-angle-left"></i></button>
                            </td>
                        </tr>

                        <tr v-else-if="area.is_online === undefined"></tr >

                        <tr v-else>
                            <td class="text-left font-weight-bold">{{ area.name }}</td>
                            <td :colspan="Object.keys(metrics).length" class="text-muted">
                                    <div v-if="area.error">{{ area.error.title }}</div>
                                    <div class="lds-ellipsis" v-else><div></div><div></div><div></div><div></div></div>
                            </td>
                            <td></td>
                        </tr>

                        <tr class="details bg-light" v-if="isSelected(area)">
                            <td :colspan="Object.keys(metrics).length + 2" class="border-top-0 text-left">
                                <AirssetChart :area="area" :metric="active_metric" :key="area.id"></AirssetChart>
                            </td>
                        </tr>

                        <tr class="spacer"><td :colspan="Object.keys(metrics).length + 2"></td></tr>
                    </template>
                    <tr class="bg-info text-white " v-if="results.length === 0">
                        <td :colspan="Object.keys(metrics).length + 2">
                            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <!--<div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <l-pagination class="pagination-no-border" v-model="pagination.current_page" :per-page="pagination.per_page" :total="pagination.total"></l-pagination>
        </div>
      -->
    </div>
</template>
<script>
    import api from '@/api';
    import { DateTime } from "luxon";
    import ReadingButton from './ReadingButton.vue';
    import AirssetChart from '@/components/AirssetChart.vue';
    import {Pagination as LPagination} from 'src/components/index'
    import { debounce } from 'vue-debounce'
    import { mapGetters } from 'vuex'

    export default {
        'components': {
            AirssetChart,
            LPagination,
            ReadingButton
        },
        'data': function () {
            return {
                'areas': [],
                'active_area': null,
                'active_metric': null,
                'query': '',
                'last_fetched_at': null,
                'interval': null,
                'pagination': {
                    per_page: null,
                    current_page: 1,
                    total: 0
                },
            };
        },
        'methods': {
            async fetch () {
                const result = await api.getOrganizationAreas(this.pagination.current_page, this.query);

                // Add placeholders for attributes so Vue can track them once we have them.
                this.areas = result.data.map(area => {
                    area.readings = Object.keys(this.metrics).reduce((carry, item) => {
                        carry[item] = null;
                        return carry;
                    }, {});

                    area.last_reading = undefined;
                    area.error = undefined;
                    area.is_online = area.devices[0]?.device_status;
                    this.last_fetched_at = DateTime.now().setZone(area.place.timezone);

                    return area;
                });

                this.pagination = {
                    per_page: result.meta.per_page,
                    current_page: result.meta.current_page,
                    total: result.meta.total,
                };
                this.update();
            },
            update () {
                this.areas.forEach(async (area) => {
                    if (area.error) {
                        return; // Don't bother fetching the latest readings for areas that had an error last time.
                    }
                    const response = await api.getAreaLatest(area.area_hashid);

                    if (typeof response.errors === 'undefined' || response.errors[0].code === 0) {
                        area.last_reading = DateTime.fromSeconds(response.data.timestamp_utc).setZone(area.place.timezone);
                        area.last_reading_to_locale = DateTime.fromSeconds(response.data.timestamp_utc).setZone(area.place.timezone).toLocaleString(DateTime.DATETIME_FULL);
                        // Set a flag if the last rewading time is greater than 10 minutes ago
                        area.is_current = (response.data.timestamp_utc < (new Date().getTime()/1000 - (60*10)) ? false : true);
                        Object.keys(area.readings).forEach(function (key) {
                            area.readings[key] = response.data[key];
                        });
                        return;
                    }

                    area.error = response.errors[0];
                });
            },
            showChart (active_area, active_metric) {
                if (this.active_area === active_area && this.active_metric === active_metric) {
                    this.active_area = null;
                    this.active_metric = null;

                    return;
                }

                this.active_area = active_area;
                this.active_metric = active_metric;
            },
            expandChart (active_area) {
                if (this.active_area === active_area) {
                    this.active_area = null;
                    this.active_metric = null;

                    return;
                }

                this.active_area = active_area;
                this.active_metric = ('temp' in this.metrics) ? 'temp' : Object.keys(this.metrics)[0]; //get first metric if no 'temp' available
            },
            'isSelected': function (area, metric = null) {
                if (!this.active_area) {
                    return false;
                }

                if (area.id !== this.active_area.id) {
                    return false;
                }

                if (metric && metric !== this.active_metric) {
                    return false;
                }

                return true;
            }

        },
        'computed': {
            'results': function () {
                return this.areas.filter((area) => area.name.toLowerCase().includes(this.query.toLowerCase()));
            },
            'colspacer': function () {
              return Object.keys(this.metrics).length + 2;
            },
            ...mapGetters([
                'metrics'
            ])
        },
        'mounted': function () {
            this.fetch();

            this.fetch(this.current_page);

            this.interval = setInterval(() => {
                this.update();
            }, 60 * 1000);
        },
        watch: {
            'pagination.current_page': function (newVal, oldVal) {
                if (newVal !== this.oldVal) {
                    this.fetch()
                }
            },
            'query': debounce(function (newVal, oldVal) {
                if (newVal !== this.oldVal) {
                    this.pagination.current_page = 1;
                    this.fetch();
                }
            }, 300)
        },
        'beforeDestroy': function () {
            clearInterval(this.interval)
        }
    }
</script>
