<template>
    <div class="row">
        <div class="col-sm-12">
            <p>Monthly Reports provide useful information and actionable insights for your company. You can access them here at any time.</p>
            <Loader v-if="loading" />
            <el-table v-else :data="reports" :default-sort="{prop: 'date', order: 'descending'}">
                <el-table-column label="Name" sortable prop="name">
                    <template slot-scope="scope">
                        <a :href="scope.row['url']" target="_blank">{{ scope.row['name'] }}</a>
                    </template>
                </el-table-column>
                <el-table-column
                  label="Date"
                  prop='date'
                  sortable
                  :formatter="formatDate"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
    import api from '@/api';
    import { Table, TableColumn } from 'element-ui'
    import { DateTime } from "luxon";
    import Loader from '@/components/Loader'

    export default {
        components: {
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Loader
        },
        methods: {
            async fetch () {
                this.loading = true;
                const result = await api.getOrganizationReports();

                this.loading = false;
                this.reports = result;
            },
            formatDate(row){
              return DateTime.fromISO(row.date).toFormat('yyyy/L/dd');
            }
        },
        mounted () {
            this.fetch();
        },
        data () {
            return {
                loading: false,
                reports: [],
            }
        }
    }
</script>
<style>
</style>
