<template>
    <div>
        <AirssetDateRangeSelector @input="event => range = event" />

        <template v-if="!is_loading && !error">
            <div class="chart-wrapper">
                <highcharts :options="options" :updateArgs="[true, true, true]" ref="chart"></highcharts>
                <SupportButton class="support-button" :areas="[area]" :metric="metric" :from="range.start" :to="range.end" />
            </div>

            <div class="d-flex justify-content-center" v-if="statistics">
                <ul class="list-inline text-muted">
                    <li class="list-inline-item">Average: {{ statistics.average.toFixed(1) }}</li>
                    <li class="list-inline-item">Min: {{ statistics.minimum }}</li>
                    <li class="list-inline-item">Max: {{ statistics.maximum }}</li>
                </ul>
                <!--<div class="text-muted">Last updated: {{ statistics.last_updated }}</div>-->
            </div>
        </template>
        <template v-else>
            <l-alert class="mb-0" type="" v-if="!error">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </l-alert>
            <l-alert class="mb-0" type="danger" v-else>{{ error }}</l-alert>
        </template>
    </div>
</template>
<script>
    import api from '@/api';
    import { DateTime } from "luxon";
    import Highcharts from "highcharts";
    import {Chart} from 'highcharts-vue';
    import LAlert from 'src/components/Alert';
    import HC_brokenAxis from "highcharts/modules/broken-axis";
    HC_brokenAxis(Highcharts);

    import AirssetDateRangeSelector from './AirssetDateRangeSelector.vue';
    import * as datefns from 'date-fns'
    import SupportButton from './SupportButton.vue';
    
    export default {
        'components': {
            'highcharts': Chart,
            LAlert,
            AirssetDateRangeSelector,
            SupportButton
        },
        'props': [
            'area',
            'metric',
        ],
        data() {
            return {
                'range': {
                    'start': datefns.startOfDay(new Date()),
                    'end': datefns.endOfDay(new Date())
                },
                'is_loading': false,
                'error': null,
                'statistics': null,
                'options': {
                    'chart': {
                        'type': 'spline',
                    },
                    'credits': {
                        'enabled': false
                    },
                    'title': {
                        'text': null,
                        'useHTML': true,
                        'style' : {
                          'fontFamily' : 'Roboto,Helvetica Neue,Arial,sans-serif',
                          'color' : '#888',
                          'fontWeight' : '700',
                          'useHTML' : true,
                        }
                    },
                    'subtitle': {
                        'text': null
                    },
                    'legend': {
                        'enabled': false
                    },
                    'xAxis': {
                        'type': 'datetime',
                        'dateTimeLabelFormats': {
                            day: '%a %e. %b',
                        },
                    },
                    'yAxis': {
                        'title': {
                            'text': null
                        },
                        'softMin': null,
                        'softMax': null,
                        'plotLines': []
                    },
                    'plotOptions': {
                        'series': {
                            'marker': {
                                'enabled': false
                            },
                            //'gapUnit': 'value',
                            'gapSize': 10
                        }
                    },
                    'series': [{
                        'name': '',
                        'data': []
                    }],
                    'time': {
                        // checkout the (-) minus here. Luxon offset has inverted sign
                        'timezoneOffset' : -DateTime.now().setZone(this.area.place.timezone).offset,
                    }
                }
            };
        },
        'methods': {
            'render': async function () {
                this.is_loading = true;
                this.error = null;

                this.updateChartData();

                // Set Series name
                this.options.series[0].name = this.$store.getters.metrics[this.metric].short_name;

                // Set text
                this.options.title.text = this.$store.getters.metrics[this.metric].name;
                this.options.subtitle.text = (this.$store.getters.metrics[this.metric].warning !== null) ? this.$store.getters.metrics[this.metric].warning : this.$store.getters.metrics[this.metric].description;
                //this.options.subtitle.text = this.$store.getters.metrics[this.metric].description;
                this.options.yAxis.title.text = this.$store.getters.metrics[this.metric].unit;

                // Set ranges
                this.options.xAxis.min = this.query.start_timestamp_utc * 1000;
                this.options.xAxis.max = this.query.end_timestamp_utc * 1000;

                // Set alert
                const user_alert = this.area.alerts.find((item) => item.metric === this.metric);

                const minimum = this.convert(user_alert ? user_alert.minimum : this.$store.getters.metrics[this.metric].thresholds.minimum);
                const maximum = this.convert(user_alert ? user_alert.maximum : this.$store.getters.metrics[this.metric].thresholds.maximum);

                const line = {
                    color: '#ffcccc',
                    opacity: 0.5,
                    dashStyle: 'ShortDash',
                    width: 2,
                    zIndex: 5,
                };

                // Set metric ranges
                this.options.yAxis.softMin = this.convert(this.$store.getters.metrics[this.metric].range.minimum);
                this.options.yAxis.softMax = this.convert(this.$store.getters.metrics[this.metric].range.maximum);

                this.options.yAxis.plotLines = [];

                if (isNaN(minimum) === false) {
                    this.options.yAxis.plotLines.push(Object.assign({
                        value: minimum,
                        label: {
                            text: 'Minimum',
                            align: 'left',
                            x: +10
                        }
                    }, line));
                }

                if (isNaN(maximum) === false) {
                    this.options.yAxis.plotLines.push(Object.assign({
                        value: maximum,
                        label: {
                            text: 'Maximum',
                            align: 'left',
                            x: +10
                        }
                    }, line));
                }

                this.is_loading = false;
            },
            'updateChartData': async function() {
              const response = await api.getAreaHistory(this.area.area_hashid, this.query);

              if (response.errors && response.errors[0].code !== 0) {
                  this.error = response.errors[0].title;
                  this.is_loading = false;
                  return;
              }
              // Chart data
              const data = response.data.map((item) => {
                  return [item.timestamp_utc * 1000, this.convert(item[this.metric])];
              });

              this.options.series[0].data = data;

              // Statistics
              const values = data.map((item) => item[1]);

              this.statistics = {
                  'average': values.reduce((a, b) => a + b) / values.length,
                  'minimum': Math.min(...values),
                  'maximum': Math.max(...values),
                  'last_updated': DateTime.fromJSDate(new Date(data[data.length - 1][0]), {zone: this.area.place.timezone}).toLocaleString(DateTime.DATETIME_FULL),
              };

            },
            'convert': function (value) {
                const metric = this.$store.getters.metrics[this.metric];

                if ('convert' in metric) {
                    value = metric.convert(value);

                    value = +(value.toFixed(1));
                }

                return value;
            }
        },
        'computed': {
            'query': function () {
                return {
                    'metric': this.metric,
                    'start_timestamp_utc': parseInt(this.range.start.getTime() / 1000),
                    'end_timestamp_utc': parseInt(this.range.end.getTime() / 1000)
                };
            },
        },
        'watch': {
            'query': function () {
                this.render();
            },
            'area': {
                deep: true,
                handler() {
                    this.updateChartData();
                }
            }
        },
        'mounted': function () {
            this.render();
        }
    }
</script>

<style>
  .highcharts-title {
    font-size: 3em;
  }
  .highcharts-background {
    color: white;
    background-color: #FF0000;
  }

  .date-btn{
    width: 3rem;
  }
  .date-select-btn {
    width: 12rem;
  }

  .chart-wrapper {
    position: relative;
  }
  .support-button {
    position: absolute;
    top: 4rem;
    right: 1rem;
  }
</style>
