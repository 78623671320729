<template>
      <div>
          <div class="row">
              <div class="col-md-12">
                  <div class="card">
                      <div class="card-body">
                          <div class="text-box justify-content-center">
                              <i>
                                <h2 class="text-primary">Air quality affects productivity</h2>
                                <p>Often, air quality metrics are difficult to understand. Standard reports and dashboards are hard to interpret and more often than not, the user will be left with more questions than answers. We want to translate your air quality into a tangible measurement that is easily understood: productivity.</p>
                                <p>Many studies have found a relationship between cognitive functioning and air quality metrics. We have compiled the findings of these studies to come up with a methodology that translates hourly air quality measurements to realistic effects in your office. The metrics that have been found to have a measurable effect on workplace performance include: temperature, carbon dioxide, volatile organic compounds (ie. chemicals), and fine particulate matter (ie. dust particles).</p>
                              </i>
                          </div>
                          <h2>The metrics, decoded</h2>
                          <p>In the past decade, air quality has been gaining recognition given its significant health impacts. Increasingly airtight buildings, energy conservation methods, and increased use of synthetic materials have given rise to concerns about indoor air quality<sup>1</sup>.</p>
                          <p>At any given time, your office's indoor air quality can be affected by multiple factors, such as:</p>
                          <ul>
                              <li>The office layout</li>
                              <li>The building's heating, ventilation, and air conditioning (HVAC) system</li>
                              <li>Outdoor environmental conditions</li>
                              <li>People inside the building</li>
                              <li>Contaminants inside the building from cooking/cleaning/other activities<sup>2</sup></li>
                          </ul>
                          <p>Monitoring devices measure and report on various indoor air quality metrics on up to a minute by minute basis, helping to determine where, when, and why your indoor air quality changes. Which helps inform decision making on maintaining a healthy and productive workplace.</p>
                          <h6 class="text-info">Virus Index</h6>
                          <ul>
                              <li>These levels are determined based on scientific analysis of five air quality factors which include Temperature, Relative Humidity, PM2.5, Carbon Dioxide, and Nitrogen Dioxide</li>
                              <li><strong>Good: </strong>COVID-19 survival is low and airborne transmission is unlikely.</li>
                              <li><strong>Mild: </strong>COVID-19 survival is moderate and airborne transmission is possible but air quality poses little to almost no direct health risk for people not sensitive to air pollution.</li>
                              <li><strong>Bad: </strong>COVID-19 survival is prolonged and airborne transmission is likely.</li>
                              <li><strong>Severe: </strong>COVID-19 survival is high and airborne transmission is likely.</li>
                          </ul>
                          <h6 class="text-info">Temperature</h6>
                          <ul>
                              <li>Measured in °C (or °F)</li>
                              <li>Temperature-related discomforts are the most common complaints in the workplace<sup>3</sup>.</li>
                              <li>Studies have shown that maintaining ideal temperatures (around 22 °C) can increase worker productivity<sup>4</sup>.</li>
                              <li>Typically maintained between 21 to 25°C during occupied hours.</li>
                              <li><b class="text-danger">Health impacts: </b>Temperatures too high (&gt; 26°C) or too low (&lt; 20°C) can cause discomfort, affecting productivity and overall sense of wellbeing.</li>
                          </ul>
                          <h6 class="text-info">Relative Humidity</h6>
                          <ul>
                              <li>Measured as a percentage (%)</li>
                              <li>Humidity is important to monitor for both occupant comfort and to prevent the growth of molds and other biological contaminants<sup>5</sup>.</li>
                              <li>Typically maintained between 40 to 70 % during occupied hours.</li>
                              <li><b class="text-danger">Health impacts: </b>Relative humidity under 35% can cause dry eyes, skin, and throat, while RH over 75% causes fatigue, overheating, and mould<sup>2</sup>.</li>
                          </ul>
                          <h6 class="text-info">Carbon Dioxide</h6>
                          <ul>
                              <li>Measured in parts per million (ppm)</li>
                              <li>CO<sub>2</sub> is a naturally-occurring atmospheric gas, and is typically maintained between 600 to 900 ppm.</li>
                              <li>The lower the CO<sub>2</sub> levels in a space the better. WorkSafeBC recommend limits no greater than 1,000 ppm for sustained periods. Occupants are typically the main source of CO<sub>2</sub> in a space. Other sources and include gas fired cooktops and other fuel burning appliances in the space<sup>1</sup>.</li>
                              <li><b class="text-danger">Health impacts: </b>Headaches, drowsiness, disorientation, fatigue, lower productivity and illness<sup>1</sup>.</li>
                          </ul>
                          <h6 class="text-info">Volatile Organic Compounds</h6>
                          <ul>
                              <li>Measured in parts per billion (ppb)</li>
                              <li>Volatile organic compounds (VOCs) are gases emitted by different types of solids and liquids, and are often found in household products such as paints, sprays, cosmetics, cleaning products, and new furniture or carpets.</li>
                              <li>The lower the VOC levels in a space, the better. Typical acceptable levels are between 0 to 800 ppb.</li>
                              <li><b class="text-danger">Health impacts: </b>Short-term effects include headaches, irritation, and fatigue/drowsiness. Long-term effects can include cancer, asthma<sup>1</sup>.</li>
                          </ul>
                          <h6 class="text-info">Fine Particulate Matter</h6>
                          <ul>
                              <li>Measured in micrograms per cubic meter (μg/m<sup>3</sup>)</li>
                              <li>Fine particulate matter (PM<sub>2.5</sub>) refers to particulate matter found in the air with a diameter less than 2.5 micrometers, often known as dust. These include combustible materials, organic compounds, metals, and other small materials that are emitted into the air, often from motor vehicles, burning wood, and office cleaning solvents. They can stay in the air for long periods of time due to their lightness.</li>
                              <li>The lower the PM<sub>2.5</sub> levels the better. These are tiny particles that can by-pass our natural air filter defenses (lungs) and go right into our bloodstream.</li>
                              <li>Typical acceptable levels are between 0 to 100 (μg/m<sup>3</sup>)</li>
                              <li><b class="text-danger">Health impacts: </b>PM<sub>2.5</sub> has been linked to heart and lung disease, asthma, bronchitis, stroke, or heart attacks. In general, PM2.5 causes airway irritation<sup>1</sup>.</li>
                          </ul>
                          <h6 class="text-info">Nitrogen Dioxide</h6>
                          <ul>
                              <li>Measured in parts parts per billion (ppb), this is a gas that is emitted when fuel is burned like smoke exhaust from a car or fumes from burning fossil fuels, natural gas, propane, wood.</li>
                              <li>Typical acceptable levels are between 0 to 100 ppb.</li>
                              <li><b class="text-danger">Health impacts: </b>Airway irritation, and respiratory issues such as coughing or wheezing (especially for occupants with asthma or bronchitis)<sup>9</sup>.</li>
                          </ul>
                          <h6 class="text-info">Ozone</h6>
                          <ul>
                              <li>Measured in parts per billion (ppb), Ozone (O<sub>3</sub>) is a naturally occurring gas, composed of three oxygen atoms. It is caused by exposure to the sun's UV rays and is the main ingredient in smog.</li>
                              <li>When found in the upper atmosphere it has a beneficial effect: shielding the earth from the sun's harmful ultraviolet rays. At ground level, however, ozone is a dangerous air pollutant and can be harmful even in very small quantities.</li>
                              <li>Ozone is sometimes used for purifying air and water and in doing so neutralizing odors, reduces VOC's and mould, airborne surface bacteria and viruses. </li>
                              <li>As a result, ASHRAE (Association of Heating Refrigeration and Air Conditioning engineers) recommend an ozone limit no greater than 50 ppb for indoor air quality requirements. </li>
                              <li><b class="text-danger">Health impacts: </b>Irritation in the throat and respiratory tract (coughing, pain, etc.). It can trigger and worsen asthma, bronchitis, emphysema, and it can cause lung damage or respiratory issues such as lung disease and lung damage<sup>1</sup>.</li>
                          </ul>
                          <h2>Conversion of air quality to cognitive functioning</h2>
                          <h6 class="text-info">Temperature</h6>
                          <p>In comparing multiple studies, it was found that the relative performance (which is a measure relative to a maximum mental performance) follows a specific parabolic curve, peaking at about 22-23°C<sup>6, 7, 8</sup>.</p>
                          <p>This calculation assumes standard clothing levels, and does not account for gender, or the influence of other air quality parameters.</p>
                          <div class="row justify-content-center">
                              <img src="/static/img/references/temp_to_cog.png" width="40%" alt="Temperature vs. Cognitive functioning">
                          </div>
                          <h6 class="text-info">Carbon Dioxide</h6>
                          <p>Carbon dioxide also has significant cognitive effects, historically being related to sick building syndrome, which can cause respiratory discomfort, fatigue, dizziness and nausea<sup>1</sup>.</p>
                          <p>Relating specifically to cognitive functioning, a study has found that “on average, a 400-ppm increase in CO2 [is] associated with a 21% decrease in a typical participant's cognitive scores across all domains”, calculating results between about 500-1500 ppm<sup>9</sup>. Another study used heart-rate variability measures as a psycho-physiological measure of invested mental effort (MF), finding that from 600 to 4000 ppm, MF decreased by about 15%<sup>10</sup>. In another test, it was found that with a baseline at “600 ppm CO2, mean raw scores at 1,000 ppm CO2 were 11–23% lower, and at 2,500 ppm CO2 were 44–94% lower. Relative to raw scores at 1,000 ppm CO2, raw scores at 2,500 ppm were 35–93% lower<sup>11</sup>”. When plotting both findings as a linear relationship between CO2 and temperature, the following relationship can be found:</p>
                          <div class="row justify-content-center">
                              <img src="/static/img/references/co2_to_cog.png" width="40%" alt="CO2 vs. Cognitive functioning">
                          </div>
                          <h6 class="text-info">Fine Particulate Matter</h6>
                          <p>The most significant known health risks from air quality are associated with the presence of fine particles (PM<sub>2.5</sub>)<sup>12</sup>.</p>
                          <p>A study measuring the effects of different levels of air quality on cognitive functioning, using a measure of change in total haemoglobin to reflect levels of mental effort, and voice quality analysis to measure effects of air quality on fatigue. It was found that with the presence of pollution (particulate matter and VOCs), complaints of physical comfort increased, and number of typing errors increased. Unfortunately, no specific measure of pollutant levels was made<sup>13</sup>.</p>
                          <p>Another study found that with a 10 µg/m3 increase in PM2.5, the likelihood of missing workdays due to air quality increased by 4.6%, with a 95% confidence interval<sup>14</sup>. Based on global research by the World Health Organization, there are about 10-15 (12.5) absences per worker per year, therefore, assuming average PM2.5 levels at 10 ug/m3 (ie. at this level, 12.5 absences per year)<sup>15</sup>, PM2.5 can be related to absences by the following relationship:</p>
                          <div class="row justify-content-center">
                              <img src="/static/img/references/pm25_to_abs.png" width="40%" alt="Days lost due to PM2.5 levels">
                          </div>
                          <h6 class="text-info">Volatile Organic Compounds</h6>
                          <p>A relationship has been found between total volatile organic compounds (TVOCs) and cognitive functioning, specifically related to memory. A 500-μg/m3 increase in TVOCs is associated with a 13% decrease in cognitive scores<sup>9</sup>.
                              Assuming a linear relationship, it can be predicted that cognitive functioning is related to VOCs in the following way:
                          </p>
                          <div class="row justify-content-center">
                              <img src="/static/img/references/voc_to_cog.png" width="40%" alt="VOCs vs. Cognitive functioning">
                          </div>

                          <h2>Key Performance Indicators Explained</h2>

                            <p>"Indoor Air Quality Key Performance Indicator (KPI) - the portion of hours during typical working hours (example based on Mon-Fri 9am - 5pm) where the IAQ measured data were within the preset limits.</p>

                            <p>e.g. If IAQ data was recorded for a total of 176 working hours in October. A KPI of 1.00 represents all 176 working hours for the month being within the preset limits.</p>

                            <p>If 48.8 working hours across the month measured values exceeded the preset PM2.5 limit (48.8 / 176 = 0.277). KPI = 1.00 - 0.277 = 0.723. The PM2.5 levels were below the preset limit (threshold) of 12µg/m³ for 72% of the total working hours measured in October.</p>

                            <p>Some KPIs take into account the hours where the IAQ was above the maximum or below the minimum preset limits (Temperature & Relative Humidity), this is because there can be adverse effects associated for example with low temperatures and relative humidities. Other KPIs only take into account the hours where the IAQ was above the maximum preset limit (CO2, VOC and PM2.5).</p>

                            <p>Virus Index - Highest Virus Index value recorded during working hours by each sensor in the month (see the Airsset dashboard for more information).</p>

                            <p>Cognitive Function - A proprietary Airsset formula calculates cognitive function based on measured air quality data."</p>

                          <h2>References &amp; Further Reading</h2>
                          <ol style="font-size: small;">
                              <li>Health Canada, Indoor Air Quality in Office Buildings : A Technical Guide.</li>
                              <li>Workers' Compensation Board of British Columbia, “Indoor Air Quality: A Guide for Building Owners, Managers, and Occupants”, 2005.</li>
                              <li>International Interior Design and Association, “Vol 2: Workplace design has a strong and definable impact on business results”, vol. 2.</li>
                              <li>O. Seppanen, W. J. Fisk, Q. H. Lei, E. Org, and O. Seppänen, “Room temperature and productivity in office work”, 2006.</li>
                              <li>U. Epa and I. Environments Division, “An Office Building Occupant's Guide to Indoor Air Quality”, 1997.</li>
                              <li>K. L. Jensen, J. Toftum, and P. Friis-Hansen, “A Bayesian Network approach to the evaluation of building design and its consequences for employee performance and operational costs”, Build. Environ., vol. 44, no. 3, pp. 456–462, 2009.</li>
                              <li>L. J. Schoen et al., “Thermal environmental conditions for human occupancy”, ASHRAE Stand., vol. 2013, no. 55, 2013.</li>
                              <li>O. Seppanen, W. J. Fisk, Q. H. Lei, E. Org, and O. Seppänen, “Room temperature and productivity in office work”, Lawrence Berkely Natl. Lab., 2006.</li>
                              <li>J. G. Allen, P. MacNaughton, U. Satish, S. Santanam, J. Vallarino, and J. D. Spengler, “Associations of cognitive function scores with carbon dioxide, ventilation, and volatile organic compound exposures in office workers: A controlled exposure study of green and conventional office environments”, Environ. Health Perspect., 2016.</li>
                              <li>L. Kajtar, L. Herczeg, E. Lang, T. Hrustinszky, and L. Banhidi, “Influence of Carbon-dioxide Pollutant on Human Well-being and Work Intensity”, in Indoor Air Quality ( IAQ ), building related diseases and human response, vol. I, no. June 2006, E. D. O. Fernandes, M. G. Silva, and J. R. Pinto, Eds. 2016, pp. 85–90.</li>
                              <li>U. Satish et al., “Is CO2 an indoor pollutant? direct effects of low-to-moderate CO2 concentrations on human decision-making performance”, Environ. Health Perspect., vol. 120, no. 12, pp. 1671–1677, 2012.</li>
                              <li>S. Schmidt, “The Benefits and Costs of the Clean Air Act from 1990 to 2020”, Environ. Health Perspect., 2019.</li>
                              <li>N. Nishihara, P. Wargocki, and S. ichi Tanabe, “Cerebral blood flow, fatigue, mental effort, and task performance in offices with two different pollution loads”, Build. Environ., 2014.</li>
                              <li>W. Health Organization and R. Office for Europe, “Health risks of air pollution in Europe – HRAPIE project, Recommendations for concentration–response functions for cost–benefit analysis of particulate matter, ozone and nitrogen dioxide”, 2013.</li>
                              <li>WHO, “Absenteeism from work due to illness, days per employee per year”, [Online]. Available: https://gateway.euro.who.int/en/indicators/hfa_411-2700-absenteeism-from-work-due-to-illness-days-per-employee-per-year/.</li>
                          </ol>
                      </div>
                  </div>
              </div>
          </div>
  </div>
</template>
<script>
  export default {
    //
  }
</script>
<style>
</style>
