<template>
    <div>
        <reports-table></reports-table>
    </div>
</template>
<script>
    import ReportsTable from './Table';

    export default {
        components: {
          ReportsTable
        }
    }
</script>
<style>
</style>
