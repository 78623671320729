export const apiHandler = {

	data() {
		return {
			saving: false,
			validationErrors: '',

		}
	},

	methods: {

		responseHandler(type, response)
		{
			switch(type) {

				case 'save':
					this.saveHandler(response);
					break;

				case 'error':
					this.errorHandler(response);
					break;

				case 'warning':
					this.warningHandler(response);
					break;

				default:
					break;
			}
		},

		saveHandler(response)
		{
			this.saving = false;

			if(response.data.redirect)
				this.$router.replace(response.data.redirect);

			this.$toasted.success(response.data.message);
      this.validationErrors = '';
		},

		warningHandler(response)
		{
			this.saving = false;

			if(response.data.redirect)
				this.$router.replace(response.data.redirect);

			this.$toasted.warning(response.data.message);
		},

		errorHandler(error)
		{
			this.saving = false;

			// display validation errors from laravel admin-app
			if(error.data.errors)
			{
				let error_message = '';
        let obj = error.data.errors;

        for (var key in obj) {
          error_message += obj[key][0] + '</br>';
        }
        this.$toasted.error(error_message);
				this.validationErrors = error_message; //for using on components

			}
			else
				this.$toasted.error('Something went wrong.');
		},

	}

}
