<template>
    <div>
        <AirssetDateRangeSelector @input="event => range = event" />


        <template v-if="!is_loading && !error">
            <div class="chart-wrapper">
                <highcharts :options="options" :updateArgs="[true, true, true]" ref="chart"></highcharts>
                <SupportButton class="support-button" :areas="chart_areas" :metric="metric" :from="range.start" :to="range.end" />
            </div>

            <div v-for="(graph, index) in options.series" :key="index" class="stats-alert">
              <p class="area-name"> {{ graph.name }} <b :style="'color:' + graph.color">&#8226;</b></p>
              <div class="d-flex justify-content-between" v-if="graph.statistics">
                  <ul class="list-inline text-muted">
                      <li class="list-inline-item">Average: {{ graph.statistics.average.toFixed(1) }}</li>
                      <li class="list-inline-item">Min: {{ graph.statistics.minimum }}</li>
                      <li class="list-inline-item">Max: {{ graph.statistics.maximum }}</li>
                  </ul>
                  <div class="text-muted">Last updated: {{ graph.statistics.last_updated }}</div>
              </div>

              <div v-if="graph.error">
                <span class="badge badge-pill"><i>{{ graph.error}}</i></span>
              </div>

            </div>
        </template>
        <template v-else>
            <l-alert class="mb-0" type="" v-if="!error">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </l-alert>
            <l-alert class="mb-0" type="danger" v-else>{{ error }}</l-alert>
        </template>
    </div>
</template>
<script>
    import api from '@/api';
    import { DateTime } from "luxon";
    import Highcharts from "highcharts";
    import {Chart} from 'highcharts-vue';
    import LAlert from 'src/components/Alert';
    import HC_brokenAxis from "highcharts/modules/broken-axis";
    HC_brokenAxis(Highcharts);
    import colors from '@/mixins/colors';

    import AirssetDateRangeSelector from './AirssetDateRangeSelector.vue';
    import * as datefns from 'date-fns'
    import SupportButton from './SupportButton.vue';

    export default {
        'components': {
            'highcharts': Chart,
            LAlert,
            AirssetDateRangeSelector,
            SupportButton
        },
        'mixins': [colors],
        'props': [
            'area',
            'metric',
            'chart_areas'
        ],
        data() {
            return {
                'range': {
                    'start': datefns.startOfDay(new Date()),
                    'end': datefns.endOfDay(new Date())
                },
                'is_loading': false,
                'selected_area': 0,
                'error': null,
                'showCalendar': false,
                'statistics': {},
                'options': {
                    'chart': {
                        'type': 'spline',
                    },
                    'credits': {
                        'enabled': false
                    },
                    'title': {
                        'text': '-',
                        'useHTML': true,
                        'style' : {
                          'fontFamily' : 'Roboto,Helvetica Neue,Arial,sans-serif',
                          'color' : '#888',
                          'fontWeight' : '700',
                          'useHTML' : true,
                        }
                    },
                    'subtitle': {
                        'text': null
                    },
                    'legend': {
                        'enabled': true,
                    },
                    'xAxis': {
                        'type': 'datetime',
                        'dateTimeLabelFormats': {
                            day: '%a %e. %b',
                        },
                    },
                    'yAxis': {
                        'title': {
                            'text': null
                        },
                        'softMin': null,
                        'softMax': null,
                        'plotLines': []
                    },
                    'tooltip': {
                      'pointFormat': '<span>{series.name}</span>'
                    },
                    'plotOptions': {
                        'series': {
                            'marker': {
                                'enabled': false
                            },
                            //'gapUnit': 'value',
                            'gapSize': 10
                        }
                    },
                    'series': [],
                    'time': {
                        // checkout the (-) minus here. Luxon offset has inverted sign
                        'timezoneOffset' : -DateTime.now().setZone(this.area.place.timezone).offset,
                    }
                },
            };
        },
        'methods': {
            'fetchData': async function(area, removed = false){
                if(removed){
                  let obj = this.options.series.findIndex(x => x.area_id === area.id);
                  this.options.series.splice(obj, 1);
                  return;
                }
                this.addArea(area);
            },
            'addArea' : async function (area){
                this.is_loading = true;
                this.updateChartDetails();
                this.error = null;

                var response = await api.getAreaHistory(area.area_hashid, this.query);

                let optionsObject =   {
                    "data" : [],
                    "area_id": area.id,
                    "name" : area.name,
                    "error" : "No data found",
                    "color" : this.getColor(),
                    "legendIndex" : area.legendIndex
                  };

                if(!response.data.length){
                  this.options.series.push(
                    {
                      "data" : [],
                      "area_id": area.id,
                      "name" : area.name,
                      "error" : "No data found",
                      "color" : this.getColor(area.legendIndex),
                      "legendIndex" : area.legendIndex
                    });
                    this.is_loading = false;
                    return;
                }

                if (response.errors && response.errors[0].code !== 0) {
                    this.error = response.errors[0].title;
                    this.is_loading = false;
                    return;
                }

                // Chart data
                const data = response.data.map((item) => {
                    return [item.timestamp_utc * 1000, this.convert(item[this.metric])];
                });

                const values = data.map((item) => item[1]);
                //push stats in with the series array so it can be accessed easily and removed with the rest
                let stats = {
                    'area' : area.name,
                    'average': values.reduce((a, b) => a + b) / values.length,
                    'minimum': Math.min(...values),
                    'maximum': Math.max(...values),
                    'last_updated': DateTime.fromJSDate(new Date(data[data.length - 1][0]), {zone: this.area.place.timezone}).toLocaleString(DateTime.DATETIME_FULL),
                };

                this.options.series.push(
                  {
                    "data": data,
                    "area_id": area.id,
                    "name" : area.name,
                    "statistics" : stats,
                    "color" : this.getColor(area.legendIndex),
                    "legendIndex" : area.legendIndex
                  });

                this.is_loading = false;

            },
            'refreshChart': async function() {
              this.options.series = []; //clear out existing chart data
              this.updateChartDetails();

              if(!this.chart_areas || !this.chart_areas.length){
                return;
              }

              //add all selected areas to chart
              for(let i = 0; i < this.chart_areas.length; i++){
                this.addArea(this.chart_areas[i]);
              }
            },
            'updateChartDetails': function (){
              this.options.title.text = this.$store.getters.metrics[this.metric].name;
            },
            'render': async function () {
                this.is_loading = true;

                // Set text
                this.options.title.text = this.$store.getters.metrics[this.metric].name;

                //this.options.title.text = this.$store.getters.metrics[this.metric].name;
                this.options.subtitle.text = (this.$store.getters.metrics[this.metric].warning !== null) ? this.$store.getters.metrics[this.metric].warning : this.$store.getters.metrics[this.metric].description;
                //this.options.subtitle.text = this.$store.getters.metrics[this.metric].description;
                this.options.yAxis.title.text = this.$store.getters.metrics[this.metric].unit;

                // Set ranges
                this.options.xAxis.min = this.query.start_timestamp_utc * 1000;
                this.options.xAxis.max = this.query.end_timestamp_utc * 1000;

                // Set alert
                const user_alert = this.area.alerts.find((item) => item.metric === this.metric);

                const minimum = this.convert(user_alert ? user_alert.minimum : this.$store.getters.metrics[this.metric].thresholds.minimum);
                const maximum = this.convert(user_alert ? user_alert.maximum : this.$store.getters.metrics[this.metric].thresholds.maximum);

                const line = {
                    color: '#ffcccc',
                    opacity: 0.5,
                    dashStyle: 'ShortDash',
                    width: 2,
                    zIndex: 5,
                };

                // Set metric ranges
                this.options.yAxis.softMin = this.convert(this.$store.getters.metrics[this.metric].range.minimum);
                this.options.yAxis.softMax = this.convert(this.$store.getters.metrics[this.metric].range.maximum);

                this.options.yAxis.plotLines = [];

                if (isNaN(minimum) === false) {
                    this.options.yAxis.plotLines.push(Object.assign({
                        value: minimum,
                        label: {
                            text: 'Minimum',
                            align: 'left',
                            x: +10
                        }
                    }, line));
                }

                if (isNaN(maximum) === false) {
                    this.options.yAxis.plotLines.push(Object.assign({
                        value: maximum,
                        label: {
                            text: 'Maximum',
                            align: 'left',
                            x: +10
                        }
                    }, line));
                }

                this.is_loading = false;
            },
            'convert': function (value) {
                const metric = this.$store.getters.metrics[this.metric];

                if ('convert' in metric) {
                    value = metric.convert(value);

                    value = +(value.toFixed(1));
                }

                return value;
            }
        },
        'computed': {
            'query': function () {
                return {
                    'metric': this.metric,
                    'start_timestamp_utc': parseInt(this.range.start.getTime() / 1000),
                    'end_timestamp_utc': parseInt(this.range.end.getTime() / 1000)
                };
            }
        },
        'watch': {
            'query': {
              'immediate': true,
              handler () {
                this.refreshChart();
                this.render();
              }
            },
            'chart_areas': {
              deep: true,
              handler(oldv,newv){
                this.refreshChart();
              }
            },
            'metric': function(){
              this.refreshChart();
            }
        },
        'mounted': function () {
            this.refreshChart();
        }
    }

</script>


<style>

.area-name{
  display: block;
  font-size: 0.8em;
  margin-bottom: 0px;
  color: gray;
}
.area-name > b {
  font-size: 1.9em;
  line-height: 0px;
}

.stats-alert{
  border-width: 1px;
  border-style: solid;
  border-color: #DDD;
  padding: 8px;
  margin: 7px;
}

.chart-wrapper {
  position: relative;
}
.support-button {
  position: absolute;
  top: 4rem;
  right: 1rem;
}
</style>
