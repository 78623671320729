<template>
    <div class="d-flex flex-wrap justify-content-between">
        <div class="btn-group mb-2">
            <button type="button" class="btn btn-default btn-outline btn-view" :class="{active: mode == '24h'}" @click="changeMode('24h')">Latest</button>
            <button type="button" class="btn btn-default btn-outline btn-view" :class="{active: mode == 'day'}" @click="changeMode('day')">Day</button>
            <button type="button" class="btn btn-default btn-outline btn-view" :class="{active: mode == 'week'}" @click="changeMode('week')">Week</button>
            <button type="button" class="btn btn-default btn-outline btn-view" :class="{active: mode == 'month'}" @click="changeMode('month')">Month</button>
        </div>

        <div class="btn-group mb-2">
            <button type="button" class="btn btn-default btn-outline date-btn" @click="previousDate">◀</button>
            <DatePicker
                mode="date"
                v-model="range"
                :max-date="new Date()"
                :model-config="datepickerModelConfig"
                is-range>

                <template v-slot="{ togglePopover }">
                    <button type="button" class="btn btn-default btn-outline date-select-btn" v-on:click="togglePopover" v-text="label" />
                </template>
            </DatePicker>
            <button type="button" class="btn btn-default btn-outline date-btn" :disabled="next_date_is_future" @click="nextDate">▶</button>
        </div>
    </div>
</template>
<script>
    import DatePicker from 'v-calendar/lib/components/date-picker.umd'
    import * as datefns from 'date-fns'

    export default {
        'components': {
            DatePicker
        },
        data() {
            return {
                range: {
                    'start': new Date(),
                    'end': new Date()
                },
                mode: '24h',
                label: '24 hours',
                datepickerModelConfig: {
                    start: {
                        timeAdjust: '00:00:00',
                    },
                    end: {
                        timeAdjust: '23:59:59',
                    }
                }
            };
        },
        'methods': {
            changeMode: function (mode) {
                if (mode === '24h') {
                    this.range = {
                        'start': datefns.startOfToday(),
                        'end': datefns.endOfToday()
                    };
                } else if (mode === 'day') {
                    const day = datefns.isToday(this.range.start) ? datefns.startOfYesterday() : datefns.startOfDay(this.range.start);

                    this.range = {
                        'start': day,
                        'end': datefns.endOfDay(day)
                    };
                } else if (mode === 'week') {
                    const end_of_week = datefns.nextSunday(this.range.start);

                    this.range = {
                        'start': datefns.setDay(this.range.start, 1),
                        'end': datefns.isFuture(end_of_week) ? new Date() : end_of_week
                    };
                } else if (mode === 'month') {
                    const end_of_month = datefns.endOfMonth(this.range.start);

                    this.range = {
                        'start': datefns.startOfMonth(this.range.start, 1),
                        'end': datefns.isFuture(end_of_month) ? new Date() : end_of_month
                    };
                }
            },
            previousDate: function () {
                if (this.mode === 'week') {
                    const start_of_previous_week = datefns.subWeeks(this.range.start, 1);
                    const end_of_previous_week = datefns.addDays(start_of_previous_week, 6);

                    this.range = {
                        'start': start_of_previous_week,
                        'end': end_of_previous_week,
                    };
                } else if (this.mode === 'month') {
                    const start_of_previous_month = datefns.subMonths(this.range.start, 1);

                    this.range = {
                        'start': start_of_previous_month,
                        'end': datefns.lastDayOfMonth(start_of_previous_month)
                    };
                } else {
                    const diff_in_days = Math.abs(datefns.differenceInDays(this.range.start, this.range.end)) + 1;

                    this.range = {
                        'start': datefns.subDays(this.range.start, diff_in_days),
                        'end': datefns.subDays(this.range.end, diff_in_days),
                    };
                }
            },
            nextDate: function () {
                if (this.mode === 'week') {
                    const end_of_next_week = datefns.addWeeks(this.range.end, 1);

                    this.range = {
                        'start': datefns.addWeeks(this.range.start, 1),
                        'end': datefns.isFuture(end_of_next_week) ? new Date() : end_of_next_week
                    };
                } else if (this.mode === 'month') {
                    const start_of_next_month = datefns.addMonths(this.range.start, 1);
                    const end_of_next_month = datefns.lastDayOfMonth(start_of_next_month);

                    this.range = {
                        'start': start_of_next_month,
                        'end': datefns.isFuture(end_of_next_month) ? new Date() : end_of_next_month
                    };
                } else {
                    const diff_in_days = Math.abs(datefns.differenceInDays(this.range.start, this.range.end)) + 1;
                    const next_end = datefns.addDays(this.range.end, diff_in_days);

                    this.range = {
                        'start': datefns.addDays(this.range.start, diff_in_days),
                        'end': datefns.isFuture(next_end) ? new Date() : next_end
                    };
                }
            }
        },
        'computed': {
            next_date_is_future: function () {
                return datefns.isToday(this.range.end);
            }
        },
        'watch': {
            range: function (range) {
                this.$emit('input', this.range);

                if (datefns.isToday(this.range.start)) {
                    this.mode = 'Latest';
                    this.label = 'Latest';
                    return;
                }

                if (datefns.isSameDay(this.range.start, this.range.end)) {
                    this.mode = 'day';
                    this.label = this.range.start.toLocaleDateString(undefined, { dateStyle: 'long' });
                    return;
                }

                if (datefns.isMonday(this.range.start) && (datefns.isSunday(this.range.end) || (datefns.isToday(this.range.end) && datefns.isThisWeek(this.range.start, {weekStartsOn: 1})))) {
                    this.mode = 'week';
                    this.label = 'w/c ' + this.range.start.toLocaleDateString(undefined, { dateStyle: 'long' });
                    return;
                }

                if (datefns.isFirstDayOfMonth(this.range.start) && datefns.isSameMonth(this.range.start, this.range.end) && (datefns.isLastDayOfMonth(this.range.end) || datefns.isToday(this.range.end))) {
                    const month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

                    this.mode = 'month';
                    this.label = month_names[this.range.start.getMonth()] + ' ' + this.range.start.getFullYear();
                    return;
                }

                this.mode = 'custom';
                this.label = this.range.start.toLocaleDateString(undefined, { dateStyle: 'long' }) + ' - ' + this.range.end.toLocaleDateString(undefined, { dateStyle: 'long' });

            }
        }
    }
</script>

<style>
    .date-btn{
        width: 3rem;
    }

    .btn.date-select-btn {
        width: 12rem;
        border-radius: 0;
        border-left-width: 0;
    }
</style>
