<template>
    <div id="support_button_wrapper" v-if="ask_expert">
        <button type="button" id="support_button" class="btn-fill btn btn-info ml-auto" @click="is_open = true">
            Ask an expert 
        </button>

        <portal to="modal">
            <el-dialog title="Ask an expert" :visible.sync="is_open" @closed="is_sent = false">
                <template v-if="!is_sent">
                    <l-alert v-if="error" type="danger" style="word-break: normal;">There was a problem trying to send your question. Please try again shortly.</l-alert>

                    <p style="word-break: normal;">Have a question about the data you are seeing? Send us a message below and one of our specialists will respond with insights or advice ASAP.</p>
                    <p>Area (metric): <strong>{{ area_list }} ({{ metric_name }})</strong></p>
                    <p>Time range: <strong>{{ start }} to {{ finish }}</strong></p>
                    <form @submit.prevent="submit">
                        <textarea class="form-control" rows="5" v-model="form.message" required></textarea>
                        <!-- <input type="hidden" value="{{ metric_name }} from {{ start }} to {{ finish }} {{ area_list }}" />-->
                        <div class="d-flex justify-content-between mt-4">
                            <button type="button" class="el-button el-button--default" @click="is_open = false">Cancel</button>
                            <button type="submit" class="btn btn-primary">Send Question</button>
                        </div>
                    </form>
                </template>
                <template v-else>
                    <p>Thanks for reaching out. We will get back to you as soon as we can.</p>
                    <span slot="footer" class="dialog-footer">
                        <button type="button" class="btn" @click="is_open = false">Close</button>
                    </span>
                </template>
            </el-dialog>
        </portal>
    </div>
</template>

<script>
import api from '@/api';
import { Button, Dialog } from 'element-ui';
import { format, formatDistance } from 'date-fns'
import LAlert from 'src/components/Alert';

export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        LAlert,
    },
    computed: {
        app_state() {
            return {
                path: this.$route.path,
                organization_name: this.$store.getters.active_organization.name,
                user_name: this.$store.getters.user.name,
            };
        },
        area_list() {
            if (this.areas.length === 1) {
                return this.areas[0].name;
            }

            return this.areas.slice(0, -1).map(area => area.name).join(', ') + ' and ' + this.areas[this.areas.length - 1].name;
        },
        interval() {
            return formatDistance(this.from, this.to);
        },
        metric_name() {
            return this.$store.getters.metrics[this.metric].name
        },
        start() {
            return format(this.from, 'LLL do yyyy');
        },
        finish() {
            return format(this.to, 'LLL do yyyy');
        },
    },
    data() {
        return {
            form: {
                message: '',
            },
            is_open: false,
            is_sent: false,
            error: null,
            ask_expert: this.$store.getters.active_organization.ask_expert,
            org_id: this.$store.getters.active_organization.id,
        }
    },
    methods: {
        submit() {
            this.is_sent = false;
            this.error = null;

            api.sendExpertRequest({
                    message: this.form.message,
                    areas: this.areas.map(area => area.name).join(', '),
                    metric: this.metric,
                    from_date: this.from,
                    to_date: this.to,
                    org_id: this.org_id
                })
                .then((result) => {
                    if (result.status === 200) {
                        this.is_sent = true;
                        this.form.message = '';
                        return;
                    }

                    // Not sure why errors aren't being rejected by the promise.
                    this.error = result.response.data.message;
                })
                .catch(error => {
                    this.error = error
                });
        }
    },
    props: {
        areas: Array,
        metric: String,
        from: Date,
        to:  Date
    }
}
</script>
