<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">

        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->

					<form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>

								<div slot="header">
									<div  class="airsset-logo mb-4">
										<img src="/images/logo.jpeg" />
									</div>
                  <h3 class="card-title text-center">Login</h3>
                </div>
                <div>
                  <div v-if="error" class="alert alert-danger">{{error}}</div>

                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="email"
                               :error="failed ? 'The Email field is required': null"
                               :hasSuccess="passed"
                               label="Email address"
                               name="email"
                               v-model="email">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="password"
                               :error="failed ? 'The Password field is required': null"
                               :hasSuccess="passed"
                               name="password"
                               label="Password"
                               v-model="password">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <div v-if="!loading">
                    <button type="submit" class="btn btn-fill btn-info btn-round btn-wd ">Login</button>
                  </div>
                  <div v-else>
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  </div>
                  <br>
                  <!-- <div class="forgot">
                    <router-link to="/register" class="card-category">
                      Forgot your password?
                    </router-link>
                  </div> -->
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import api from '../../../api';
import firebase from "firebase/app";

extend("email", email);
extend("required", required);
extend("min", min);

  export default {
    components: {
      LCheckbox,
      FadeRenderTransition,
      AuthLayout
    },
    data() {
      return {
        email: "",
        password: "",
        subscribe: true,
        error: "",
        loading: false,
      };
    },
    methods: {
      async submit() {
        this.loading = true;
        this.error = null;
        const api_token = await api.getApiToken(this.email, this.password);

        if(api_token.response !== undefined && api_token.response.status >= 422){
          this.error = 'Invalid Credentials';
          this.loading = false;
          return;
        }
        localStorage.setItem('api_token', api_token.data);
        this.loading =  api_token ? true : false;
        this.$router.push('admin/overview');
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    },
		mounted () {
			this.email = process.env.VUE_APP_EMAIL;
			this.password = process.env.VUE_APP_PASS;

		}
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
