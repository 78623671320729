var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.dialogFormVisible = true}}},[_vm._v("Edit")]),_c('el-dialog',{attrs:{"title":'Edit alerts: ' + _vm.area.name,"visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('p',[_vm._v("Enter your own thresholds or use the recommended ones.")]),_c('div',{staticClass:"form-row mb-2"},[_c('div',{staticClass:"col-4"},[_c('strong',[_vm._v("Metric")])]),_c('div',{staticClass:"col-2"},[_c('strong',[_vm._v("Min")])]),_c('div',{staticClass:"col-2"},[_c('strong',[_vm._v("Max")])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_vm._l((_vm.metrics),function(metric,key){return _c('div',{key:_vm.metrics[key].short_name,staticClass:"form-row form-group"},[_c('div',{staticClass:"col-4"},[_c('label',[_vm._v(_vm._s(metric.short_name))])]),_c('div',{staticClass:"col-2"},[_c('ValidationProvider',{attrs:{"rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Should be numeric' : null,"hasSuccess":passed}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.metrics[key].minimum),expression:"form.metrics[key].minimum"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.form.metrics[key].minimum)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.metrics[key], "minimum", $event.target.value)}}})])]}}],null,true)})],1),_c('div',{staticClass:"col-2"},[_c('ValidationProvider',{attrs:{"rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Should be numeric' : null,"hasSuccess":passed}},[_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.metrics[key].warning),expression:"metrics[key].warning"},{name:"model",rawName:"v-model",value:(_vm.form.metrics[key].maximum),expression:"form.metrics[key].maximum"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.form.metrics[key].maximum)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.metrics[key], "maximum", $event.target.value)}}})])]}}],null,true)})],1),_c('div',{staticClass:"col"},[_c('span',{staticClass:"form-text"},[_vm._v(_vm._s(metric.unit_symbol))])])])}),_c('el-button',{staticClass:"mb-3",attrs:{"type":"secondary"},on:{"click":_vm.setDefaults}},[_vm._v("Set recommended thresholds for all")]),_c('div',{staticClass:"form-row mb-2"},[_c('div',{staticClass:"col-4"},[_c('strong',[_vm._v("Enable alerts")])]),_c('div',{staticClass:"col-6"},[_c('l-switch',{model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}})],1)]),_c('div',{staticClass:"form-row mb-2"},[_c('div',{staticClass:"col-4"},[_c('strong',[_vm._v("Recipient")])]),_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{ref:"observer",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? 'must provide valid email': null,"hasSuccess":passed}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"type":"email","value":"","multiple":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})])]}}],null,true)})],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogFormVisible = false}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("Confirm")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }