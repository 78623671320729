import Vue from 'vue'
import Vuex from 'vuex'
import metrics_json from './metrics'
import api from '@/api';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    active_organization: {},
    metrics: {},
    places: [],
    unpaired_devices: []
  },

  getters: {
    user: state => {
      return state.user;
    },
    active_organization: state => {
      return state.active_organization;
    },
    metrics: state => {
      return state.metrics;
    },
    places: state => {
      return state.places;
    },
    unpaired_devices: state => {
      return state.unpaired_devices;
    }
  },


  mutations: {
    setUser(state, user) {
      state.user = user;
			setOrganisation(state);
    },
		setActiveOrganization(state, organization_id){
			setOrganisation(state, organization_id);
		},
    setPlaces(state, places) {
      state.places = places;
    },
    setUnpairedDevices(state, unpaired_devices) {
      state.unpaired_devices = unpaired_devices;
    },
    unsetUser(state) {
      state.user = null;
    }
  }
});

		async function setOrganisation(state, id = null){
      // Set the active organization. Local storage may contain a preference for this.
      const active_organization_id = id ?? localStorage.getItem('active_organization_id');

      let active_organization = state.user.organizations.find(organization => organization.id === active_organization_id);

      if (!active_organization) {
        console.log('no active org found setting to ' + state.user.organizations[0].name);
        active_organization = state.user.organizations[0];
      }

      localStorage.setItem('active_organization_id', active_organization.id);
      state.active_organization = active_organization;

      const metrics = await api.getMetrics();

      if('temp' in metrics){
        if (active_organization.is_imperial) {
            metrics.temp.unit = 'Farenheit';
            metrics.temp.unit_symbol = '°F';
            metrics.temp.convert = value => value * 1.8 + 32;
            metrics.temp.convertBack = value => (value - 32) / 1.8;
        }else{
            metrics.temp.unit = 'Celsius';
            metrics.temp.unit_symbol = '°C';
            if('convert' in metrics.temp){
              delete metrics.temp.convert;
              delete metrics.temp.convertBack;
            }
          }
      }
      state.metrics = metrics;

	}
