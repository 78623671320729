import Vue from 'vue'
import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Alerts from './pages/Dashboard/Alerts/Alerts.vue'
import DashboardLayout from './pages/Dashboard/Layout/DashboardLayout.vue'
import Login from './pages/Dashboard/Pages/Login.vue'
import NotFound from './pages/GeneralViews/NotFoundPage.vue'
import Organization from './pages/Dashboard/Organization/Organization.vue'
import ChangeOrg from './pages/Dashboard/Organization/ChangeOrg.vue'
import History from './pages/Dashboard/History/History.vue'
import Reports from './pages/Dashboard/Reports/Reports.vue'
import Overview from './pages/Dashboard/Overview/Overview.vue'
import Resources from './pages/Dashboard/Resources.vue'
import Support from './pages/Dashboard/Support.vue'

import store from './store'
import api from './api'

Vue.use(VueRouter);

const router = new VueRouter({
  history: () => createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/admin/overview'
    }, {
      path: '/login',
      name: 'Login',
      component: Login
    }, {
      path: '/admin',
      component: DashboardLayout,
      redirect: '/admin/overview',
      children: [
        {
          path: 'overview',
          name: 'Overview',
          component: Overview,
          meta: { needs_auth: true }
        },
        {
          path: 'resources',
          name: 'Resources',
          component: Resources,
          meta: { needs_auth: true }
        },
        {
          path: 'support',
          name: 'Support',
          component: Support,
          meta: { needs_auth: true }
        },
        {
          path: 'alerts',
          name: 'Alerts',
          component: Alerts,
          meta: { needs_auth: true }
        },
        {
          path: 'organization',
          name: 'Organization',
          component: Organization,
          meta: { needs_auth: true }
        },
        {
          path: 'organization/change',
          name: 'Change Organization',
          component: ChangeOrg,
          meta: { needs_auth: true }
        },
        {
          path: 'analysis',
          name: 'Analysis',
          component: History,
          meta: { needs_auth: true }
        },
        {
          path: 'reports',
          name: 'Reports',
          component: Reports,
          meta: { needs_auth: true }
        }
      ]
    },
    {path: '*', component: NotFound}
  ],
  linkActiveClass: 'active'
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.needs_auth) {
    // If we don't have a token, redirect to login.
    if (!localStorage.getItem('api_token')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });

      return;
    }

    // If we have a token but the user isn't loaded, load the user.
    if (!store.getters.user) {
      const user = await api.getUser();
      store.commit('setUser', user);
    }
  }

  next();
});

export default router;
